import React from 'react'
import useStyles from './useStyles'

const CalendarPage = () => {
  const classes = useStyles()

  return <h1>CalendarPage</h1>
}

export default CalendarPage
