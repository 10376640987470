import React from 'react'
import ReactDOM from 'react-dom'
import HomePage from './pages/HomePage'
import CalendarPage from './pages/CalendarPage'
import { routes } from './app/pageRoute'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import NavBar from './components/NavBar'
import 'bootstrap/dist/css/bootstrap.min.css'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path={routes.CalendarPage} element={<CalendarPage />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
)
