import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tabs'

export default function ImgMediaCard() {
  return (
    <Card sx={{ maxWidth: 305 }}>
      <CardMedia
        component="img"
        alt="Resident Picture"
        height="300"
        image="https://images.unsplash.com/photo-1472214103451-9374bd1c798e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cGljfGVufDB8fDB8fA%3D%3D&w=1000&q=80"
      />
      <CardContent>
        <Typography gutterBottom variant="h5">
          Name: Resident Card
        </Typography>
        <Tabs defaultActiveKey="Profile" id="id" className="mb-3">
          <Tab eventKey="Profile" title="Profile">
            <Typography variant="body1" color="text.secondary">
              Active:
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Occupation:
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Open To Work:
            </Typography>

            <Typography variant="body1" color="text.secondary">
              Cycle:
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Wage:
            </Typography>
          </Tab>
          <Tab eventKey="Contact Info" title="Contact Info">
            <Typography variant="body1" color="text.secondary">
              Email:
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Phone:
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Website:
            </Typography>
            <Typography variant="body1" color="text.secondary">
              LinkedIn:
            </Typography>
          </Tab>
        </Tabs>
      </CardContent>
    </Card>
  )
}
